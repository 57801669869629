import { useEffect, useRef } from 'react';
import cx from 'classnames';
import type { ButtonProps, ObjectShorthandValue } from '@archipro-design/aria';
import { Button, useStyles } from '@archipro-design/aria';
import type { SvgIconSizeValue } from '@archipro-design/icons';
import { HeartFill, HeartLine } from '@archipro-design/icons';
import { useHandleFollow } from '~/modules-rocco/design-board/hook/use-handle-follow';
import type { UserFavouritedItem } from '../type/design-board-types';
import * as S from './SaveToDesignBoard.style';

export interface SaveToDesignBoardProps {
    itemId: UserFavouritedItem['itemId'];
    itemType: UserFavouritedItem['itemType'];
    libraryItemId?: UserFavouritedItem['libraryItemId'];
    target?: HTMLElement;
    clickToken?: number;
    offsetLeft?: string;
    offsetTop?: string;
    children?: React.ReactNode;
    onSave: (favouritedItem: UserFavouritedItem) => void;
    onClose?: () => void;
    heartButton?: ObjectShorthandValue<
        ButtonProps & { heartSize?: SvgIconSizeValue }
    >;
    isFavourited?: boolean;
    closeOnScrollOffset?: number; // close when scroll offset is greater than this value
    toastAutoHideDelay?: number;
    isInitializeOnly?: boolean;
    className?: string;
    disable?: boolean;
    label?: string;
    skipSinglePinSaving?: boolean;
    afterSkipSaving?: (boardId: number, title?: string) => void;
}

const SaveToDesignBoard = ({
    itemId,
    itemType,
    libraryItemId,
    target,
    clickToken,
    children,
    className,
    heartButton = {},
    isInitializeOnly = false,
    label,
}: SaveToDesignBoardProps) => {
    const { handleFollow, isInDesignBoard } = useHandleFollow();

    const { css } = useStyles();

    const clickTokenRef = useRef<number>(0);

    const renderAriaHeartButton = () => {
        if (!heartButton) {
            return null;
        }

        const { heartSize, ...heartButtonProps } = heartButton;

        return (
            <Button
                icon={
                    isInDesignBoard(itemId, itemType) ? (
                        <HeartFill size={heartSize || '2x'} />
                    ) : (
                        <HeartLine size={heartSize || '2x'} />
                    )
                }
                className={cx(
                    heartButtonProps.className,
                    css(S.SaveToDesignBoard)
                )}
                size={36}
                transparent={true}
                {...heartButtonProps}
                variables={{ buttonPadding: 4, ...heartButtonProps.variables }}
            >
                {label}
            </Button>
        );
    };

    const showHeartButton = !target && !isInitializeOnly;

    useEffect(() => {
        if (target && clickToken) {
            // ensure one click triggers only one popup
            if (clickToken !== clickTokenRef.current) {
                handleFollow({ id: itemId, libraryItemId }, itemType);
                clickTokenRef.current = clickToken || 0;
            }
        }
    }, [target, clickToken, handleFollow, itemId, itemType, libraryItemId]);

    return (
        <>
            {showHeartButton && (
                <div
                    className={className}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        handleFollow({ id: itemId, libraryItemId }, itemType);
                        e.preventDefault();
                    }}
                >
                    {children || renderAriaHeartButton()}
                </div>
            )}
        </>
    );
};

export default SaveToDesignBoard;
